import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VisualLinkService {

  visualLink = { // Default Styles
    bodyBackground: "#ffffff",

    headerBackground: "#ffffff", 
    headerColor: "#282828",
    headerLogo: null,

    cardBackground: "#aac81c",
    cardColor: "#ffffff",

    formBackground: "#ffffff",
    formColor: "#282828",

    primary: "#aac81c",
    contrast: "#ffffff"
  }

  constructor() { }

  setVisualLink(entity) {
    let attributeGroup = entity.attributeGroups ? entity.attributeGroups.find(item => item.name == "VisualLink") : null;
    let stylesPrev = attributeGroup && attributeGroup.attributes ? attributeGroup.attributes.find(item => item.name == "visualLinkSettings") : null;
    let visualLink = stylesPrev ? JSON.parse(stylesPrev.value) : null;

    this.visualLink.bodyBackground = visualLink && visualLink.bodyBackground ? visualLink.bodyBackground : this.visualLink.bodyBackground;

    this.visualLink.headerBackground = visualLink && visualLink.headerBackground ? visualLink.headerBackground : this.visualLink.headerBackground;
    this.visualLink.headerColor = visualLink && visualLink.headerColor ? visualLink.headerColor : this.visualLink.headerColor;
    this.visualLink.headerLogo = visualLink && visualLink.headerLogo ? visualLink.headerLogo : entity.logoUrl;

    this.visualLink.cardBackground = visualLink && visualLink.cardBackground ? visualLink.cardBackground : this.visualLink.cardBackground;
    this.visualLink.cardColor = visualLink && visualLink.cardColor ? visualLink.cardColor : this.visualLink.cardColor;

    this.visualLink.formBackground = visualLink && visualLink.formBackground ? visualLink.formBackground : this.visualLink.formBackground;
    this.visualLink.formColor = visualLink && visualLink.formColor ? visualLink.formColor : this.visualLink.formColor;

    this.visualLink.primary = visualLink && visualLink.primary ? visualLink.primary : this.visualLink.primary;
    this.visualLink.contrast = visualLink && visualLink.contrast ? visualLink.contrast : this.visualLink.contrast;

    // this.injectStyle();
    return this.visualLink;
  }

  getVisualLinkStyle(visualLink) {
    return `
      /* BODY */
      body {
        background: ${this.getBackground(visualLink.bodyBackground)};
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
      }

      /* HEADER */
      .logo-wrapper {
        background: url(${visualLink.headerLogo});
      }
      .header-container {
        color: ${this.hexaToRgba(visualLink.headerColor, 0.87)};
      }
      .header-line {
        border-bottom: 2px solid ${this.getBackground(visualLink.headerBackground)};
      }

      /* FORM BACKGROUND */
      mat-card.mat-card { 
        background: ${this.getBackground(visualLink.formBackground)};
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        color: ${this.hexaToRgba(visualLink.formColor, 0.87)};
      }
      /* LINE DIVIDER */
      .mat-divider {
        border-top-color: ${this.hexaToRgba(visualLink.formColor, 0.18)};
      }
      /* INPUT APPEARANCE */
      .mat-input-element::placeholder{
        color: ${this.hexaToRgba(visualLink.formColor, 0.6)};
      }
      .mat-form-field-appearance-outline .mat-form-field-outline {
        color: ${this.hexaToRgba(visualLink.formColor, 0.18)};
      }
      .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
        color: ${visualLink.primary};
      }
      .mat-form-field.mat-focused .mat-form-field-label {
        color: ${visualLink.primary};
      }
      .mat-form-field-label {
        color: ${this.hexaToRgba(visualLink.formColor, 0.6)};
      }
      /* TABLE APPEREANCE */
      .mat-cell, .mat-footer-cell {
        color: ${this.hexaToRgba(visualLink.formColor, 0.87)};
      }

      /* PAYMENT METHOD OPTION BUTTON  */
      .method-button-selected   {
        color: ${visualLink.cardColor};
        border-color: ${visualLink.cardBackground};
        background: ${this.hexaToRgba(visualLink.cardBackground, 0.12)};
      }
      .method-button-unselected   {
        /* color: ${visualLink.cardColor}; */
        color: ${visualLink.formColor};
        border-color: ${this.hexaToRgba(visualLink.formColor, 0.12)};
        /* background: ${this.hexaToRgba(visualLink.formBackground, 0.12)}; */
        background: inherit;
      }

      /* CHECKBOX */
      .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
        background-color: ${visualLink.primary};
      }
      .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
        background: ${visualLink.primary};
      }
      .mat-checkbox-frame {
        border-width: 1px !important;
        border-color: ${this.hexaToRgba(visualLink.formColor, 0.18)};
      }
    
      /* HREF LINK */
      .m-link {
        color: ${visualLink.primary};
        opacity: .75;
        font-size: 12px;
        font-weight: 500;
      }
      .m-link:hover {
          color: ${visualLink.primary};
          opacity: 1;
      }
      .m-link:hover {
          border-bottom: 1px solid ${visualLink.primary};
          opacity: 1;
          filter: alpha(opacity=30)
      }
      button:disabled {
        background-color: ${this.hexaToRgba(visualLink.formColor, 0.18)} !important;
        color: ${this.hexaToRgba(visualLink.formColor, 0.18)} !important;
      }
    
      button.mat-flat-button.mat-button-base {
        background-color: ${visualLink.primary};
        color: ${visualLink.contrast};
      }

      /* TERMS AND CONDITIONS BUTTON */
      .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
        background-color: ${visualLink.primary};
        color: ${visualLink.contrast};
      }

      /* PAYMENT FORM */
      div[synap-container="form"] div[synap-container^="field-"] {
        padding: 0px 2px 10px 2px;
      }
      div[synap-container="form"] div[synap-container^="field-"] input {
        color: ${this.hexaToRgba(visualLink.formColor, 0.87)};
        background: inherit;
        border: 1px solid ${this.hexaToRgba(visualLink.formColor, 0.18)};
        border-radius: 5px 5px 5px 5px;
      }
      div[synap-container="form"] div[synap-container^="field-"] input::placeholder {
        color: ${this.hexaToRgba(visualLink.formColor, 0.6)};
      }
      div[synap-container="form"] div[synap-container^="field-"] input:focus {
        border:2px solid ${visualLink.primary};
      }
      div[synap-identifier="payment-button"] {
        background-color: ${visualLink.primary};
        color: ${visualLink.contrast};

        margin-top: 10px;
        border-radius: 5px;
        height: 50px !important;
    
        font-size: 18px;
        font-family: Roboto, "Helvetica Neue", sans-serif;
      }
      div[synap-identifier="loading"] {
        position: absolute;
      }
    `;
  }

  hexaToRgba(hexa, opacity) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hexa = hexa.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexa);
    let rgb = result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
    return `rgba(${rgb ? rgb.r : ''}, ${rgb ? rgb.g : ''}, ${rgb ? rgb.b : ''}, ${opacity})`;
  }

  getBackground(hexaOrUrl) {
    hexaOrUrl = hexaOrUrl && hexaOrUrl.substring(0, 1) == "#" ? hexaOrUrl : `url(${hexaOrUrl})`;
    return hexaOrUrl;
  }
}
