<div class="footer">
    <div class="row footer-container">
        <div class="col-sm-6 w-half">
            <div class="box">
                <div class="left-container">
                    <div class="powered-container">
                        <div class="powered-text">
                            Powered by
                        </div>
                    </div>
                    <div class="logo-container">
                        <div class="synapsis-logo">
                            <img src="/assets/img/SYNAPSIS_DARK.svg">
                        </div>
                        <div *ngIf="action == 'ADD'" class="processor-logo">
                            <img src="/assets/img/{{this.myForm.get('processorCode').value}}.svg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6 w-half">
            <div class="box">
                <div class="right-container">
                    <div class="powered-container">
                        <div class="powered-text">
                            &nbsp;
                        </div>
                    </div>
                    <div class="pci-container">
                        <div class="pci-logo">
                            <img src="/assets/img/PCI.svg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>