<!--<div *ngIf="transaction" class="cssanimation sequence fadeInBottom">-->
<div *ngIf="transaction">
    <div class="row center-xs">
        <div class="col-xs-12 col-sm-7">
            <div class="box">
                <mat-card>
                    <mat-card-content>
                        <div class="row start-xs not-printable">
                            <div class="col-xs-12">
                                <div class="box">
                                    <app-header-form #childHeader></app-header-form>
                                </div>
                            </div>
                        </div>
                        <div class="synap-body-response">
                            <div class="not-printable" *ngIf="response.success && response.result.accepted">
                                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                            </div>
                            <div class="not-printable" *ngIf="response.success && !response.result.accepted">
                                <svg class="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle class="crossmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <line class="checkmark__check" fill="none" x1="17" y1="15" x2="36" y2="38" />
                                    <line class="checkmark__check" fill="none" x1="36" y1="15" x2="17" y2="38" />
                                </svg>
                            </div>
                            <div class="not-printable"
                                *ngIf="!(response.success && response.result.accepted) && !(response.success && !response.result.accepted)">
                                <!-- <img class="synap-img-error"
                                    src="https://button.pay.synapsolutions.com/web/1.2.0/resources/images/synap-sprite.png"> -->
                                <svg class="warning" fill="#FF9738" width="69px" height="69px" viewBox="0 0 256 256"
                                    id="Flat" xmlns="http://www.w3.org/2000/svg">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0" />
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                                    <g id="SVGRepo_iconCarrier">
                                        <path
                                            d="M128,24A104,104,0,1,0,232,128,104.11759,104.11759,0,0,0,128,24Zm-8,56a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm8,104a12,12,0,1,1,12-12A12,12,0,0,1,128,184Z" />
                                    </g>
                                </svg>
                            </div>
                            <div class="printable">
                                <img class="{{response.success && response.result.accepted ? 'synap-img-success' : (response.success && !response.result.accepted ? 'synap-img-denied' : 'synap-img-error')}}"
                                    src="https://button.pay.synapsolutions.com/web/1.2.0/resources/images/synap-sprite.png">
                            </div>
                            <div
                                class="synap-message-response {{response.success && response.result.accepted ? 'synap-success' : response.success && !response.result.accepted ? 'synap-denied' : 'synap-error'}}">
                                <!--<span>{{response.success ? response.result.message : response.message.text}}</span>-->
                                <!-- <span>{{(response.success && response.result.accepted) ? 'Felicitaciones, tu pago ha
                                    sido exitoso' : ((response.success && !response.result.accepted) ? 'Lo sentimos, tu
                                    pago ha sido rechazado' : response.message.text) }}</span> -->
                                <span>{{getResponseTxt(response)}}</span>
                            </div>
                            <div class="synap-fieldlist-response">
                                <div class="synap-field-response">
                                    <div class="synap-fieldname-response">Orden:</div>
                                    <div class="synap-fieldvalue-response">{{transaction.order.number}}</div>
                                </div>
                                <div class="synap-field-response" *ngIf="response.success && response.result.accepted">
                                    <div class="synap-fieldname-response">Cliente:</div>
                                    <div class="synap-fieldvalue-response">
                                        {{transaction.order.customer.name +' '+ transaction.order.customer.lastName}}
                                    </div>
                                </div>
                                <div class="synap-field-response" *ngIf="response.success && response.result.accepted">
                                    <div class="synap-fieldname-response">Tarjeta:</div>
                                    <div class="synap-fieldvalue-response">
                                        {{response.payment.card.bin +'******'+ response.payment.card.lastPan}}</div>
                                </div>
                                <div class="synap-field-response" *ngIf="response.success">
                                    <div class="synap-fieldname-response">Fecha:</div>
                                    <div class="synap-fieldvalue-response">
                                        {{response.result.processingTimestamp | date: 'dd MMM yyyy hh:mm a' : '-0500'}}
                                    </div>
                                </div>
                                <div class="synap-field-response">
                                    <div class="synap-fieldname-response">Monto:</div>
                                    <div class="synap-fieldvalue-response">{{transaction.order.currency.symbol}}
                                        {{transaction.order.amount | number: '.2'}}</div>
                                </div>
                                <div class="synap-field-response" *ngIf="response.success && !response.result.accepted">
                                    <div class="synap-fieldname-response">Mensaje:</div>
                                    <div class="synap-fieldvalue-response">
                                        <!-- {{response.result.processorResult ? response.result.processorResult.message :
                                        'Error en procesador'}} -->
                                        <ol class="list-message" type=”A”>
                                            <li>Activa las compras por internet de tu tarjeta</li>
                                            <li>Revisa los límites de compras y saldo de tu tarjeta</li>
                                            <li>Revisa los datos de tu tarjeta</li>
                                        </ol>
                                    </div>
                                </div>
                                <div class="synap-field-response">
                                    <div class="synap-fieldname-response">Comercio:</div>
                                    <div class="synap-fieldvalue-response">{{transaction.order.entity.name}}</div>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
    <div class="row center-xs" id="button-container">
        <div class="col-xs-12 col-sm-7">
            <div class="box">
                <div class="synap-button-row">
                    <!--<button mat-flat-button color="primary" *ngIf="isAuthorized && !isAlreadyAuthorized"-->
                    <button mat-flat-button color="primary" *ngIf="isAuthorized" (click)="printResponse()"
                        class="not-printable">Imprimir</button>
                    <!--<button mat-flat-button color="primary" *ngIf="!isAuthorized && !isAlreadyAuthorized && !isAlreadyReversed"-->
                    <button mat-flat-button color="primary" *ngIf="isRePayable" (click)="retryPayment()">Reintentar
                        Pago</button>
                    <!--<button mat-flat-button color="primary" (click)="printResponse()">Imprimir</button>-->
                </div>
            </div>
        </div>
    </div>
</div>