import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-footer-form',
  templateUrl: './footer-form.component.html',
  styleUrls: ['./footer-form.component.css']
})
export class FooterFormComponent implements OnInit {
  myForm: FormGroup;
  action: null;

  constructor(private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef) {
    this.myForm = this.formBuilder.group({
      'processorCode': [null],
    });
  }

  ngOnInit(): void {
  }

  setFooterProcessor(action, processor) {
    this.reset();
    this.action = action;

    if (this.action == 'ADD') {
      this.myForm.get('processorCode').setValue(processor.code);
    }
    this.cdr.detectChanges();
  }

  reset() {
    this.myForm.reset;
    this.cdr.detectChanges();
  }
}
