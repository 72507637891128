import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Transaction } from 'src/app/models/transaction.model';
import { CustomerFormComponent } from '../../customer/form/customer-form.component';
import { MessageService } from 'src/app/services/message.service';
import { TermsConditionsFormComponent } from '../../terms-conditions/form/terms-conditions-form.component';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Processor } from 'src/app/models/processor.models';
import { HeaderFormComponent } from '../../header/form/header-form.component';
import { FooterFormComponent } from '../../footer/form/footer-form.component';

@Component({
  selector: 'app-link-form',
  templateUrl: './link-form.component.html',
  styleUrls: ['./link-form.component.css']
})
export class LinkFormComponent implements OnInit, OnDestroy {
  myForm: FormGroup;

  static EVENT_GET_LINK = 'GET_LINK';
  static EVENT_SET_PAYMENT = 'SET_PAYMENT';
  static EVENT_SET_PAYMENT_BANK = 'SET_PAYMENT_BANK';

  transaction: Transaction;
  @ViewChild('childCustomer') childCustomer: CustomerFormComponent;
  @ViewChild('childHeader') childHeader: HeaderFormComponent;

  acceptTerms = false;

  processorsVisual = {
    KASHIO: {
      methodButtonLabel: 'Banca móvil, agentes y bodegas',
      payButtonLabel: 'Generar código de pago'
    },
    PAGOEFECTIVO: {
      methodButtonLabel: 'Banca móvil, QR y agentes',
      payButtonLabel: 'Generar código de pago (CIP)'
    },
    NIUBIZ: {
      methodButtonLabel: 'Tarjeta de crédito y débito',
      payButtonLabel: 'Continuar al pago'
    },
    IZIPAY: {
      methodButtonLabel: 'Tarjeta de crédito y débito',
      payButtonLabel: 'Continuar al pago'
    },
    KUSHKI: {
      methodButtonLabel: 'Tarjeta de crédito y débito',
      payButtonLabel: 'Continuar al pago'
    },
    DFPROC: {
      methodButtonLabel: 'Tarjeta de crédito y débito',
      payButtonLabel: 'Continuar al pago'
    }
  }

  processors: Processor[];
  processorSelected: number = 0;

  constructor(private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private dialog: MatDialog) {
    this.myForm = this.formBuilder.group({
      'description': [null],
      'checkTerms': [null, Validators.required]
    });
  }

  ngOnInit(): void {
  }

  setOrder(transaction: Transaction) {
    this.transaction = transaction;

    const cardList = [];
    const bankList = [];
    for (let processor of this.transaction.settings.procesors) {
      if (processor.brands[0].code == 'BANKS') {
        bankList.push(processor);
      }
      else {
        cardList.push(processor);
      }
    }
    this.transaction.settings.procesors = cardList.concat(bankList);
    this.setVisualLinkProcessingIdentifiers();

    if (this.transaction.order.orderType.code == 'SUBSCRIPTION') {
      // this.transaction.settings.procesors = cardList;
      this.transaction.features.registerFrequent = true;

      // Obteniendo todas las claves del JSON
      for (var clave in this.processorsVisual) {
        // Controlando que json realmente tenga esa propiedad
        if (this.processorsVisual.hasOwnProperty(clave)) {
          this.processorsVisual[clave].payButtonLabel = 'Continuar la suscripción'
        }
      }
    }

    // this.processors = this.transaction.settings.procesors;
    this.reset();

    const actionHeader = this.transaction.order.orderType.code == 'ORDER_OPEN_AMOUNT' ? 'ADD' : 'SET';
    this.childHeader.setHeaderAmount(actionHeader, this.transaction.order.currency.symbol, this.transaction.order.amount);

    const actionCustomer = this.transaction.order.targetType.code == 'MULTIPLE' ? 'ADD' : 'SET';
    this.childCustomer.setCustomer(actionCustomer, actionCustomer == 'ADD' ? null : this.transaction.order.customer);

    this.myForm.get('description').setValue(this.transaction.order.products[0].name);

    this.cdr.detectChanges();
  }

  setVisualLinkProcessingIdentifiers() {
    const attributeGroup = this.transaction.order.entity.attributeGroups ? this.transaction.order.entity.attributeGroups.find(element => element.name == "VisualLink") : null;
    const visualLinkProcessorsSettings = attributeGroup && attributeGroup.attributes ? attributeGroup.attributes.find(item => item.name == "visualLinkProcessorsSettings") : null;
    const visualLinkProcessors = visualLinkProcessorsSettings ? JSON.parse(visualLinkProcessorsSettings.value) : null;
    
    this.processors = []
    if (visualLinkProcessors) {
      for (let processor of this.transaction.settings.procesors) {
        const tempProcessingIdentifier = visualLinkProcessors.find(element => element.code == processor.code);
        if (tempProcessingIdentifier && tempProcessingIdentifier.checked) {
          this.processors.push(processor);
        }
        else if (!tempProcessingIdentifier) {
          this.processors.push(processor);
        }
      }
    }
    else {
      for (let processor of this.transaction.settings.procesors) {
        this.processors.push(processor);
      }
    }
  }

  setPayment() {
    this.childCustomer.onSubmit();
    this.childHeader.onSubmit();

    if (this.childCustomer.myForm.valid && this.childHeader.myForm.valid) {
      if (this.transaction.order.targetType.code == 'MULTIPLE') {
        this.transaction.order.customer.name = this.childCustomer.myForm.get("name").value.trim();
        this.transaction.order.customer.lastName = this.childCustomer.myForm.get("lastName").value.trim();
        this.transaction.order.customer.document.type = this.childCustomer.myForm.get("documentType").value.trim();
        this.transaction.order.customer.document.number = this.childCustomer.myForm.get("documentNumber").value.trim();
        // this.transaction.order.customer.phone = "+51" + this.childCustomer.myForm.get("phone").value.trim();
        this.transaction.order.customer.phone = this.childCustomer.myForm.get("phone").value.trim();
        this.transaction.order.customer.email = this.childCustomer.myForm.get("email").value.trim();
      }
      this.transaction.order.amount = this.childHeader.myForm.get("amount").value;
      this.messageService.sendMessage('SET_PAYMENT', { transaction: this.transaction, processor: this.processors[this.processorSelected] });
    }
  }

  checkSelectedTerms() {
    this.acceptTerms = this.myForm.get("checkTerms").value;
  }

  openDialog() {
    const dialogRef = this.dialog.open(TermsConditionsFormComponent, {
      //width: '600px',
      //height:'800px',
      data: {
        message: 'Estas seguro?',
        buttonText: {
          ok: 'Acepto',
          cancel: 'No Gracias'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      this.myForm.get("checkTerms").setValue(confirmed);
      this.acceptTerms = this.myForm.get("checkTerms").value;
    });
  }

  reset() {
    this.myForm.reset();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
