import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private subject = new Subject<any>();

  constructor() { }

  /*sendMessage(message: any) {
    this.subject.next({ obj: message });
  }*/

  sendMessage(name: string, message: any) {
    this.subject.next({ name: name, parameter: message });
  }

  /*clearMessages() {
    this.subject.next();
  }

  getObject(): Observable<any> {
    return this.subject.asObservable();
  }*/

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
