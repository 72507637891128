import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError, timeout } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private serviceUrl = environment.API_HOST;

  constructor(private http: HttpClient) { }

  getOrder(orderIdentifier: string): Observable<any> {
    const url = `${this.serviceUrl}order/operation/orders/${orderIdentifier}`
    //const url = `https://synapsis.getsandbox.com/order-search-rest/orders/${orderIdentifier}`
    // const url = `https://pagadiferente1.getsandbox.com/order-search-rest/orders/${orderIdentifier}`

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.get<any>(url, { headers, observe: 'response' })
      .pipe(
        timeout(10000),
        /*catchError(error => {
          return Observable.throw(error);
        })*/
        catchError(this.handleError)
      );
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
