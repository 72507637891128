import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Customer } from 'src/app/models/customer.model';
import { ViewEncapsulation } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerFormComponent implements OnInit, OnDestroy {
  myForm: FormGroup;
  action: string = 'ADD'; //ADD, SET, DEL

  constructor(private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef) {
    this.myForm = this.formBuilder.group({
      'name': [null],
      'lastName': [null],
      'documentType': ['DNI'],
      'documentNumber': [null],
      'phone': [null],
      'email': [null]
    })
  }

  ngOnInit(): void {
  }

  resetValidators() {
    this.myForm.get('name').setValidators(null);
    this.myForm.get('lastName').setValidators(null);
    this.myForm.get('documentNumber').setValidators(null);
    this.myForm.get('phone').setValidators(null);
    this.myForm.get('email').setValidators(null);
  }

  setDefaultValidators() {
    this.myForm.get('name').setValidators([Validators.required, Validators.pattern("^([a-zA-ZÀ-ÿ, .’-]{3,})*$")]);
    this.myForm.get('lastName').setValidators([Validators.required, Validators.pattern("^([a-zA-ZÀ-ÿ, .’-]{3,})*$")]);
    this.myForm.get('documentNumber').setValidators([Validators.required, Validators.pattern("^([0-9]{8,})*$")]);
    this.myForm.get('phone').setValidators([Validators.required, Validators.pattern("^([1-9]{1}[0-9]{6,})*$")]);
    this.myForm.get('email').setValidators([Validators.required, Validators.email]);
  }

  updateValidators() {
    this.myForm.get('name').updateValueAndValidity();
    this.myForm.get('lastName').updateValueAndValidity();
    this.myForm.get('documentNumber').updateValueAndValidity();
    this.myForm.get('phone').updateValueAndValidity();
    this.myForm.get('email').updateValueAndValidity();
  }

  setCustomer(action: string, customer: Customer) {
    this.reset();
    this.action = action;
    this.resetValidators();

    if (this.action == 'ADD') {
      this.setDefaultValidators();
    }
    else {
      this.myForm.get("name").setValue(customer.name.trim());
      this.myForm.get("lastName").setValue(customer.lastName.trim());
      this.myForm.get("documentType").setValue(customer.document.type.trim());
      this.myForm.get("documentNumber").setValue(customer.document.number.trim());
      const phone = customer.phone.substring(0, 1) == '+' ? customer.phone.substring(3) : customer.phone;
      this.myForm.get("phone").setValue(phone.trim());
      this.myForm.get("email").setValue(customer.email.trim());
    }
    this.updateValidators();
    this.cdr.detectChanges();
  }



  isFieldValid(field: string) {
    return !this.myForm.get(field).valid && this.myForm.get(field).touched;
  }

  onSubmit() {
    if (this.myForm.valid) {
      console.log('form valid');
    } else {
      this.validateAllFormFields(this.myForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  get name() {
    return this.myForm.get("name");
  }

  get lastName() {
    return this.myForm.get("lastName");
  }

  get documentNumber() {
    return this.myForm.get("documentNumber");
  }

  get phone() {
    return this.myForm.get("phone");
  }

  get email() {
    return this.myForm.get("email");
  }

  public errorMessages = {
    name: [
      { type: 'required', message: 'Debes ingresar un valor' },
      { type: 'pattern', message: 'No es un formato válido' }
    ],
    lastName: [
      { type: 'required', message: 'Debes ingresar un valor' },
      { type: 'pattern', message: 'No es un formato válido' }
    ],
    documentNumber: [
      { type: 'required', message: 'Debes ingresar un valor' },
      { type: 'pattern', message: 'No es un formato válido' }
    ],
    phone: [
      { type: 'required', message: 'Debes ingresar un valor' },
      { type: 'pattern', message: 'No es un formato válido' }
    ],
    email: [
      { type: 'required', message: 'Debes ingresar un valor' },
      { type: 'email', message: 'No es un formato válido' }
    ]
  }

  reset() {
    this.myForm.reset;
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
