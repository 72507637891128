<mat-dialog-content>


    <div class="row">
        <div class="col-xs-12">
            <div class="box">

                <h2>Términos y Condiciones Usuario Pagador </h2>
                <h5>Última actualización: 05 de Enero 2022</h5>

                <p>Bienvenido a Synapsis, a partir de este momento disfrutarás de una experiencia de pago
                    simple, ágil y segura (PCI Compliance), muchas gracias por tu preferencia.
                </p>

                <p>Synapsis es una empresa que facilita los pagos en línea entre los clientes compradores y los
                    comercios afiliados a Synapsis.
                </p>

                <h4>ASPECTOS GENERALES </h4>
                <ol type="a">
                    <li>SYNAPSIS SOLUTIONS S.A.C. es una empresa legalmente constituida con domicilio legal
                        en la ciudad de Lima - Perú, la misma que proporciona servicios tecnológicos de pago por
                        canal web, móvil iOS y Android, link, bot y suscripciones.
                    </li>

                    <li>CLIENTE PAGADOR (TARJETA HABIENTE) es cualquier persona que realice pagos a través de la
                        plataforma, por cualquiera de los canales tecnológicos ofrecidos por Synapsis. Así
                        mismo EL CLIENTE PAGADOR es el único responsable de la información que ingrese a través
                        de otras aplicaciones para facilitar el ingreso de información (autocompletar datos desde
                        los exploradores web).
                    </li>

                    <li>LA PLATAFORMA acepta pagos con Tarjetas Crédito o Débito de las marcas
                        Visa, MasterCard, American Express o Diners, que cuenten con código de seguridad (CVV2); asi
                        mismo, LA PLATAFORMA acepta pagos a través de banca móvil, QR (Yape, Plin, y otros)
                        y agentes a través de PagoEfectivo y KashIO.
                    </li>
                </ol>

                <h4>USO DE LA PLATAFORMA </h4>
                <ol type="a">
                    <li>GESTION DE PAGO: Con la aceptación del CLIENTE PAGADOR de los Términos y
                        Condiciones, SYNAPSIS permite el uso de sus servicios de procesamiento de pagos de
                        acuerdo a las instrucciones del COMERCIO con el fin de que el COMERCIO
                        perciba del USUARIO el pago por un producto o servicio.
                    </li>

                    <li>Toda compra realizada por este medio no tiene derecho a cambio, anulación o devolución. Se
                        recomienda que el CLIENTE PAGADOR confirme los montos de pago antes de concretar el pago.
                    </li>
                </ol>

                <h4>RESPONSABILIDAD DE LAS PARTES </h4>
                <!-- <p> El COMERCIO es responsable por el correcto uso de la plataforma de pagos electrónicos
                    de Synapsis, para los fines con que este fue desarrollado y aplicado a la legislación
                    vigente.
                </p> -->

                <ol type="a">
                    <li>EL COMERCIO es responsable por el correcto uso de la plataforma de pagos
                        electrónicos de Synapsis, para los fines con que este fue desarrollado y aplicado a
                        la legislación vigente.
                    </li>

                    <li>RESPONSABILIDAD POR RECLAMOS DEL CLIENTE PAGADOR: Es responsabilidad del COMERCIO
                        asumir todos los reclamos que presenten los CLIENTES PAGADORES en cuanto a la calidad, cantidad
                        y
                        entrega de los bienes y servicios que sean vendidos. Por tal motivo tanto el CLIENTE PAGADOR
                        como el
                        COMERCIO exoneran a Synapsis de toda responsabilidad por este concepto,
                        sin perjuicio de que Synapsis pueda requerir al COMERCIO para que atienda
                        los reclamos presentadas por los CLIENTES PAGADORES.
                    </li>

                    <li>RESPONSABILIDAD DEL COMERCIO POR EL USO DE LA PLATAFORMA: EL COMERCIO
                        se compromete a brindar información veraz y oportuna a los CLIENTES PAGADORES en su sitio web,
                        categoría de productos o servicios que a través de él se ofrezcan o comercialicen y que se
                        paguen a través de la PLATAFORMA DE PAGOS de Synapsis.
                    </li>

                    <li>RESPONSABILIDAD DEL CLIENTE PAGADOR Y COMERCIO: Es responsabilidad del CLIENTE PAGADOR y del
                        COMERCIO las obligaciones contraídas entre AMBOS en relación a los pagos realizados
                        a través de la PLATAFORMA DE PAGOS, entendiendo que el vínculo existente con Synapsis es solo
                        por la gestión del pago, siendo ajeno al contrato que vincula al COMERCIO Y CLIENTE PAGADOR,
                        por ende aceptan ambos exonerar a Synapsis por cualquier perjuicio que
                        puedan sufrir con ocasión a la transacción realizada.
                    </li>

                    <li>RESPONSABILIDAD DE SYNAPSIS: Synapsis se compromete a prestar sus
                        servicios de forma diligente y competente para garantizar al máximo la seguridad y éxito en
                        las transacciones, sin perjuicio de las posibles demoras en el procesamiento de los pagos,
                        fraude por suplantación o cualquier imprevisto que pueda ocurrir por fuerza mayor o caso
                        fortuito que impida el funcionamiento normal de la plataforma y esté relacionado con el
                        servicio prestado.
                    </li>
                </ol>


                <h5>ANEXOS</h5>
                <p>Son parte del presente contrato y por tanto de estricto cumplimiento las siguientes políticas y
                    directrices:</p>

                <h4>POLÍTICA DE PAGOS</h4>
                <p>El CLIENTE PAGADOR que desee realizar un pago a través de la PLATAFORMA deberá conocer los
                    diferentes estados en que se ve reflejada una transacción.
                </p>
                <p>Sea cual sea el método de pago a utilizar, cuando un CLIENTE PAGADOR realiza un pago a través de
                    Synapsis,
                    el CLIENTE PAGADOR podrá visualizar el estado de la transacción en línea y el COMERCIO recibirá una
                    notificación a sus servidores, esto sin perjuicio a que por fuerza mayor o caso fortuito
                    Synapsis tenga que acudir a otros métodos para notificar el estado de las transacciones.
                </p>
                <p>LOS CLIENTES PAGADORES y LOS COMERCIOS, deberán estar atentos a los pagos que están realizando,
                    entendiéndose que los únicos responsables por la verificación del estado
                    de las transacciones son EL CLIENTE PAGADOR Y EL COMERCIO. Synapsis no se hace responsable por la
                    entrega de determinado producto o prestación de servicios que el
                    COMERCIO debiera entregar al CLIENTE PAGADOR.
                </p>

                <h4>SERVICIO AL CLIENTE: </h4>
                <p>Si tienes alguna inquietud o reclamo acerca de los servicios prestados por Synapsis,
                    recuerde que para ello hemos habilitado diferentes métodos de contacto para que sus dudas sean
                    resueltas de la manera más eficiente posible.
                </p>
                <p>Para ello tenemos a su disposición personal
                    capacitado e idóneo en las diferentes áreas (comercial, administrativo y de soporte) que
                    estará dispuesto a atenderlo en los canales de contacto autorizados por Synapsis a través de su
                    área de atención al cliente.
                </p>
                <p>El contacto es a través de nuestro sitio web: www.synapsis.pe o a través de correo electrónico:
                    soporte@synapsis.pe.</p>

                <h4>CONTRA-CARGOS </h4>
                <p>Un contra cargo es la solicitud que un CLIENTE PAGADOR presenta ante el banco para solicitar la
                    cancelación de una transacción bien sea por desconocimiento de la compra o argumentando la
                    presencia de un posible fraude.
                </p>
                <p>Es importante tener en cuenta que el contra cargo no deberá presentarse por inconformidad con
                    el producto o servicio prestado, para ello deberá iniciar un proceso de garantía con el
                    COMERCIO quien es el único responsable por la satisfacción del CLIENTE PAGADOR.
                </p>
                <p>Es importante tener en cuenta que los contra cargos son procedimientos ajenos a Synapsis
                    este es un procedimiento que se desenvuelve entre el banco emisor de la tarjeta de crédito o
                    débito y el CLIENTE PAGADOR.
                </p>
                <p>La responsabilidad de atender el contra cargo es 100% del COMERCIO sin perjuicio de la
                    mediación que pueda realizar Synapsis para encausar la documentación suministrada y
                    hacerse participe de los procesos.
                </p>


                <h5>NOTA:</h5>
                <p>*El procedimiento de los contra cargos es un procedimiento ajeno a Synapsis, en el
                    que este, solo participa como mediador quedando exento de cualquier responsabilidad que pueda
                    sobrevenir con ocasión a estos.
                </p>

                <p>*Los documentos aportados por el COMERCIO como prueba para validar la compra y
                    atender el contra cargo, son analizados por personal del banco emisor, quienes determinaran
                    su validez, veracidad e idoneidad. Synapsis no se hace responsable por la
                    información recibida a través del COMERCIO como medio de prueba, información que se
                    presumirá veraz y legal, sin perjuicio de las acciones legales que pueda entablar
                    Synapsis o el banco emisor, en contra del COMERCIO, cuando éste entregue
                    información falsa o adulterada para respaldar una compra y atender dichas disputas.
                </p>

                <p>*Los Términos y Condiciones pueden ser modificados en cualquier momento. Si cualquier
                    modificación es hecha, la divulgación será publicada en este mismo aplicativo. Es
                    responsabilidad del usuario revisar los términos y condiciones del aplicativo y del uso de
                    la plataforma de pago para estar al tanto de los cambios realizados.
                </p>

            </div>
        </div>
    </div>


</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-raised-button mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
    <button mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
</mat-dialog-actions>