import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Entity } from 'src/app/models/entity.model';


@Component({
  selector: 'app-terms-conditions-form',
  templateUrl: './terms-conditions-form.component.html',
  styleUrls: ['./terms-conditions-form.component.css']
})
export class TermsConditionsFormComponent implements OnInit {

  message: string = "Estas seguro?"
  confirmButtonText = "Acepto"
  cancelButtonText = "No Gracias"
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<TermsConditionsFormComponent>) {
    if (data) {
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  ngOnInit() {
  }
}
