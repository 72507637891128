import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { HttpClientModule } from '@angular/common/http'; 
//import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LinkFormComponent } from './content/pages/link/form/link-form.component';
import { HeaderFormComponent } from './content/pages/header/form/header-form.component';
import { FooterFormComponent } from './content/pages/footer/form/footer-form.component';
import { CustomerFormComponent } from './content/pages/customer/form/customer-form.component';
import { PaymentFormComponent } from './content/pages/payment/form/payment-form.component';
import { PaymentResponseFormComponent } from './content/pages/payment-response/form/payment-response-form.component';
import { LandingFormComponent } from './content/pages/landing/form/landing-form.component';
import { ErrorFormComponent } from './content/pages/error/form/error-form.component';
import { TermsConditionsFormComponent } from './content/pages/terms-conditions/form/terms-conditions-form.component';
import { NumericDirective } from './directives/numeric.directive';
import { CurrencyPipe, DecimalPipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    LinkFormComponent,
    HeaderFormComponent,
    FooterFormComponent,
    CustomerFormComponent,
    PaymentFormComponent,
    PaymentResponseFormComponent,
    LandingFormComponent,
    ErrorFormComponent,
    TermsConditionsFormComponent,
    NumericDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    //FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    CurrencyPipe, DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
