// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_HOST: 'https://sbx.api.pay.synapsolutions.com/',
  ID_ENVIRONMENT: 'sandbox',
  SDK_PAYMENT: 'https://sbx.button.pay.synapsolutions.com/web/latest/sdk.js',
  GATEWAY_KASHIO: 'https://cert-gateway.kashio.net/e/index.html?token=',
  GATEWAY_PAGOEFECTIVO: 'https://pre1a.payment.pagoefectivo.pe/',
};

// export const environment = {
//   production: false,
//   API_HOST : 'https://api.pay.synapsolutions.com/',
//   ID_ENVIRONMENT : 'production',
//   SDK_PAYMENT : 'https://button.pay.synapsolutions.com/web/latest/sdk.js',
//   GATEWAY_KASHIO: 'https://gateway.kashio.net/e/index.html?token=',
//   GATEWAY_PAGOEFECTIVO: 'https://payment.pagoefectivo.pe/',
// };
  
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
