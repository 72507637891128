<form [formGroup]="myForm">
    <div class="row">
        <div class="{{this.myForm.get('documentType').value == 'RUC' ? 'col-xs-12' : 'col-xs-6 col-sm-6'}}">
            <div class="box">
                <mat-form-field appearance="outline">
                    <mat-label>{{this.myForm.get('documentType').value == 'RUC' ? 'Empresa' : 'Nombre'}}</mat-label>
                    <input matInput placeholder="Nombre" formControlName="name" [readonly]="action == 'SET'"
                        maxlength="100" autocomplete="off">
                    <mat-error class="my-error" *ngIf="isFieldValid('name')">
                        <ng-container *ngFor="let error of errorMessages.name">
                            <small *ngIf="this.myForm.get('name').hasError(error.type)">{{error.message}}</small>
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>

        </div>
        <div class="col-xs-6 col-sm-6" *ngIf="this.myForm.get('documentType').value != 'RUC'">
            <div class="box">
                <mat-form-field appearance="outline">
                    <mat-label>Apellido</mat-label>
                    <input matInput placeholder="Apellido" formControlName="lastName" [readonly]="action == 'SET'"
                        maxlength="100" autocomplete="off">
                    <mat-error class="my-error" *ngIf="isFieldValid('lastName')">
                        <ng-container *ngFor="let error of errorMessages.lastName">
                            <small *ngIf="this.myForm.get('lastName').hasError(error.type)">{{error.message}}</small>
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-xs-6 col-sm-6">
            <div class="box">
                <mat-form-field appearance="outline">
                    <!-- <mat-label>{{targetType && targetType.code == 'SINGLE' ? this.myForm.get('documentType').value :
                        'DNI o Pasaporte'}}</mat-label> -->
                    <mat-label>{{this.myForm.get('documentType').value}}</mat-label>
                    <input matInput placeholder="DNI" formControlName="documentNumber" [readonly]="action == 'SET'"
                        maxlength="20" autocomplete="off">
                    <mat-error class="my-error" *ngIf="isFieldValid('documentNumber')">
                        <ng-container *ngFor="let error of errorMessages.documentNumber">
                            <small
                                *ngIf="this.myForm.get('documentNumber').hasError(error.type)">{{error.message}}</small>
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-xs-6 col-sm-6">
            <div class="box">
                <mat-form-field appearance="outline">
                    <mat-label>Teléfono</mat-label>
                    <input matInput placeholder="Teléfono" formControlName="phone" [readonly]="action == 'SET'"
                        maxlength="30" autocomplete="off">
                    <mat-error class="my-error" *ngIf="isFieldValid('phone')">
                        <ng-container *ngFor="let error of errorMessages.phone">
                            <small *ngIf="this.myForm.get('phone').hasError(error.type)">{{error.message}}</small>
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-xs-12">
            <div class="box">
                <mat-form-field appearance="outline">
                    <mat-label>Correo</mat-label>
                    <input matInput placeholder="Correo" formControlName="email" [readonly]="action == 'SET'"
                        maxlength="200" autocomplete="off">
                    <mat-error class="my-error" *ngIf="isFieldValid('email')">
                        <ng-container *ngFor="let error of errorMessages.email">
                            <small *ngIf="this.myForm.get('email').hasError(error.type)">{{error.message}}</small>
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
</form>