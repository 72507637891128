import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Transaction } from 'src/app/models/transaction.model';
import { MessageService } from 'src/app/services/message.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-header-form',
  templateUrl: './header-form.component.html',
  styleUrls: ['./header-form.component.css']
})
export class HeaderFormComponent implements OnInit {
  myForm: FormGroup;

  static EVENT_SET_PAYMENT_AMOUNT = 'SET_PAYMENT_AMOUNT';
  
  action: null;
  transaction: Transaction;
  // regExpAmount = "^[1-9][0-9]{0,3}(\.?[0-9]{1,2})?$";
  regExpAmount = "^[0-9]{1,7}(\.?[0-9]{1,2})?$";

  subscription: Subscription;

  constructor(private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService) {
    this.myForm = this.formBuilder.group({
      'currencySymbol': [null],
      'amount': [null],
    });
  }

  ngOnInit(): void {
  }

  resetValidators() {
    this.myForm.get('amount').setValidators(null);
  }

  setDefaultValidators() {
    this.myForm.get('amount').setValidators([Validators.required, Validators.pattern(this.regExpAmount), this.amountRangeValidator]);
  }

  updateValidators() {
    this.myForm.get('amount').updateValueAndValidity();
  }

  amountRangeValidator(control: FormControl) {
    if (control.value && control.value.trim() != '' && control.value[0] != '.' && control.value[control.value.length-1] != '.') {
      if (control.value >= 1 && control.value <= 9000) {
        return null;
      }
      return { invalidAmountRange: true };  
    }
    return null;
  }

  setHeaderAmount(action, currencySymbol, amount) {
    this.reset();
    this.action = action;
    this.resetValidators();

    if (this.action == 'ADD') {
      this.setDefaultValidators();
    }
    else {
      this.myForm.get('currencySymbol').setValue(currencySymbol);
      this.myForm.get('amount').setValue(amount);        
    }
    this.updateValidators();
    this.cdr.detectChanges();
  }

  isFieldValid(field: string) {
    return !this.myForm.get(field).valid && this.myForm.get(field).touched;
  }

  onSubmit() {
    if (this.myForm.valid) {
      console.log('form valid');
    } else {
      this.validateAllFormFields(this.myForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  get amount() {
    return this.myForm.get("amount");
  }

  public errorMessages = {
    amount: [
      { type: 'required', message: 'Debes ingresar un valor' },
      { type: 'pattern', message: 'No es un formato válido' },
      { type: 'invalidAmountRange', message: 'Ingresar un valor entre 1 y 9mil'}
    ]
  }

  reset() {
    this.myForm.reset;
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
