<div *ngIf="transaction">
    <div class="row center-xs">
        <div class="col-xs-12 col-sm-7">
            <div class="box">
                <mat-card>
                    <mat-card-content>
                        <div class="row start-xs">
                            <div class="col-xs-12">
                                <div class="box">
                                    <app-header-form #childHeader></app-header-form>
                                </div>
                            </div>
                        </div>
                        <div class="row start-xs">
                            <div class="col-xs-12">
                                <div class="box">
                                    <div class="sub-title">
                                        <div class="sub-title-left" *ngIf="transaction.order.orderType.code != 'SUBSCRIPTION'">DETALLE DE LINK</div>
                                        <div class="sub-title-left" *ngIf="transaction.order.orderType.code == 'SUBSCRIPTION'">DETALLE DE SUSCRIPCION</div>
                                    </div>
                                    <div>
                                        <mat-divider></mat-divider>
                                        <app-customer-form #childCustomer></app-customer-form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form [formGroup]="myForm">
                            <div class="row start-xs">
                                <div class="col-xs-12">
                                    <div class="box">
                                        <div class="pad-top-1 pad-bottom-10">
                                            <div class="col-xs-12">
                                                <div class="box">
                                                    <mat-form-field appearance="outline">
                                                        <mat-label>Descripción</mat-label>
                                                        <input matInput placeholder="Correo"
                                                            formControlName="description" [readonly]="true">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="row start-xs">
                            <div class="col-xs-12">
                                <div class="box">
                                    <div class="sub-title">
                                        <div class="sub-title-left">
                                            METODO DE PAGO
                                        </div>
                                    </div>
                                    <div class="pad-bottom-10">
                                        <mat-divider></mat-divider>
                                        <div class="row">
                                            <div class="col-xs-12 method-button-container"
                                                *ngFor="let processor of processors; let index = index"
                                                [ngClass]="((index + 1) % 2) && ((index + 1) == processors.length) ? '' : 'col-sm-6'">
                                                <button title="{{processor.code}}"
                                                    [ngClass]="processorSelected == index ? 'method-button-selected': 'method-button-unselected'"
                                                    class="flex items-center justify-center w-full h-60 flex-col border border-solid rounded-5"
                                                    (click)="processorSelected = index;">
                                                    <div style="text-align: -webkit-center; height: 100%;"
                                                        *ngIf="processor.brands.length == 1">
                                                        <div style="height: 35%; display: flex;">
                                                            <div
                                                                style="display: inline-block; align-self: flex-end; width: 100%;">
                                                                {{processorsVisual[processor.code].methodButtonLabel}}
                                                            </div>
                                                        </div>
                                                        <div
                                                            style="display: flex; justify-content: center; padding-top: 5px;">
                                                            <img src="/assets/img/{{processor.code}}.svg" alt="method"
                                                                class="block" [ngClass]="processor.code">
                                                        </div>
                                                    </div>
                                                    <div style="text-align: -webkit-center; height: 100%;"
                                                        *ngIf="processor.brands.length > 1">
                                                        <div style="height: 35%; display: flex;">
                                                            <div
                                                                style="display: inline-block; align-self: flex-end; width: 100%;">
                                                                {{processorsVisual[processor.code].methodButtonLabel}}
                                                            </div>
                                                        </div>
                                                        <div
                                                            style="display: flex; justify-content: center; padding-top: 8px;">
                                                            <img *ngFor="let brand of processor.brands; let index = index"
                                                                src="/assets/img/{{brand.code}}.svg" alt="method"
                                                                class="block" [ngClass]="brand.code">
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row start-xs">
                            <div class="col-xs-12">
                                <div class="box">
                                    <div class="synap-button-row">
                                        <button [disabled]="!acceptTerms" mat-flat-button color="primary"
                                            (click)="setPayment()">
                                            {{this.processorsVisual[processors[processorSelected].code].payButtonLabel}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row start-xs">
                            <div class="col-xs-12">
                                <div class="box">
                                    <div class="synap-terms">
                                        <form [formGroup]="myForm">
                                            <mat-checkbox color="primary" formControlName="checkTerms"
                                                (change)="checkSelectedTerms()">
                                                <span>Acepto los</span>
                                            </mat-checkbox>
                                        </form>
                                        <div class="synap-link">
                                            <a class="m-link" (click)="openDialog()">términos y condiciones</a>
                                            <span *ngIf="transaction.order.orderType.code == 'SUBSCRIPTION'">&nbsp; de suscripción</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>