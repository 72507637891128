<div class="row center-xs">
    <div class="col-xs-12 col-sm-7 synap-content">
        <div class="box">
            <mat-card>
                <mat-card-content>
                    <div class="synap-error-container">
                        <div class="synap-img-error-container">
                            <img class="synap-img-error"
                                src="https://button.pay.synapsolutions.com/web/1.2.0/resources/images/synap-sprite.png">
                        </div>
                        <div class="synap-error-title">
                            <span>UUUPS!!!</span>
                        </div>
                        <div class="synap-error-body">
                            <span>Algo salió mal, por favor verifica el link e intenta nuevamente</span>
                        </div>
                        <div class="synap-error-ps">
                            <span>Si el problema persiste, ponte en contacto con tu comercio y solicítalo
                                nuevamente</span>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>