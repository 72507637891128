<div class="header">
    <form [formGroup]="myForm">
        <div class="header-container header-line">
            <div class="header-logo-container">
                <div class="logo-wrapper"></div>
            </div>
            <div class="header-amount-container">
                <div *ngIf="action == 'SET'">
                    <div class="header-amount-label">
                        Monto
                    </div>
                    <div class="header-amount">
                        {{this.myForm.get('currencySymbol').value}} {{this.myForm.get('amount').value | number: '.2-2'}}
                    </div>
                </div>
                <div *ngIf="action == 'ADD'">
                    <mat-form-field class="amount-form-field" appearance="underline" floatLabel="always">
                        <mat-label>Monto</mat-label>
                        <span matSuffix class="amount-suffix">{{this.myForm.get('currencySymbol').value}}&nbsp;</span>
                        <input class="amount-input" matInput placeholder="0.00" formControlName="amount" numeric
                            decimals="2" maxLength="7" autocomplete="off">
                        <mat-error class="amount-error" *ngIf="isFieldValid('amount')">
                            <ng-container *ngFor="let error of errorMessages.amount">
                                <small *ngIf="this.myForm.get('amount').hasError(error.type)">{{error.message}}</small>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</div>