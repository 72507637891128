<!-- Header -->
<!-- <app-header-form #childHeader></app-header-form> -->

<!-- <div class="content" [ngClass]="!showHeader ? 'content-gateway' : ''"> -->
<div class="content">

  <div class="cssanimation fadeInBottom" [hidden]="widgetId != 'synap-landing-form'">
    <app-landing-form #childLanding></app-landing-form>
  </div>
  <div class="cssanimation fadeInBottom" [hidden]="widgetId != 'synap-link-form'">
    <app-link-form #childLink></app-link-form>
  </div>
  <div class="cssanimation fadeInBottom" [hidden]="widgetId != 'synap-payment-form'">
    <app-payment-form #childPayment></app-payment-form>
  </div>
  <div class="cssanimation fadeInBottom" [hidden]="widgetId != 'synap-payment-response-form'">
    <app-payment-response-form #childPaymentResponse></app-payment-response-form>
  </div>
  <div class="cssanimation fadeInBottom" [hidden]="widgetId != 'synap-error-form'">
    <app-error-form #childError></app-error-form>
  </div>
</div>

<!-- Footer -->
<div class="footer">
  <div class="row center-xs">
    <div class="col-xs-12 col-sm-7">
      <div class="box">

        <div class="row start-xs">
          <div class="col-xs-12">
            <div class="box">
              <app-footer-form #childFooter></app-footer-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>