import { Component, OnInit, ChangeDetectorRef, Renderer2, Inject } from '@angular/core';
import { Transaction } from 'src/app/models/transaction.model';
import { environment } from 'src/environments/environment';
import { MessageService } from 'src/app/services/message.service';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { PaymentService } from 'src/app/services/payment.service';
import { DOCUMENT } from '@angular/common';
import { VisualLinkService } from 'src/app/services/visual-link.service';
import { Processor } from 'src/app/models/processor.models';

declare const Synap: any;

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.css']
})
export class PaymentFormComponent implements OnInit {
  static EVENT_SET_RESPONSE = 'SET_RESPONSE';
  static EVENT_SET_ERROR = 'SET_ERROR';

  transaction: Transaction;
  processor: Processor;
  response: any;
  paymentMethod = 'CARDS';
  urlSafe: SafeHtml;
  isLoading = false;

  constructor(private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private paymentService: PaymentService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: any,
    private visualLinkService: VisualLinkService) { }

  ngOnInit(): void {
  }

  setPayment(transaction: Transaction, processor: Processor) {
    // this.isLoading = true;

    this.reset();
    this.transaction = transaction;
    this.processor = processor;
    this.cdr.detectChanges();
    switch (processor.code) {
      case 'KASHIO':
        this.startPaymentBank(transaction, processor, environment.GATEWAY_KASHIO, null);
        break;
      case 'PAGOEFECTIVO':
        this.startPaymentBank(transaction, processor, environment.GATEWAY_PAGOEFECTIVO, null);
        break;
      case 'NIUBIZ':
      case 'IZIPAY':
      default:
        this.startPayment();
        break;
    }
    // this.isLoading = false;
    this.cdr.detectChanges();
  }

  goPay() {
    let buttonPay = document.querySelector("div[synap-identifier='payment-button']") as HTMLElement;
    const rpta = buttonPay.click();
  }


  async startPayment() {
    this.paymentMethod = 'CARDS';
    var orderMinimalData = this.transaction; //jsonTransaction.data;
    var settings = {
      "brands": this.transaction.settings.brands, //jsonTransaction.data.settings.brands, //['VISA', 'MSCD', 'AMEX', 'DINC'],
      "language": this.transaction.settings.language, //jsonTransaction.data.settings.language == null ? localeString : jsonTransaction.data.settings.language,
      "autogenerate": this.transaction.settings.autogenerate,
      "businessService": 'LNK', //jsonTransaction.data.settings.businessService,
    }
    orderMinimalData['settings'] = settings;

    const me = this;
    var form = new Synap.Form({
      transaction: orderMinimalData,
      environment: environment.ID_ENVIRONMENT, //'sandbox',
      listeners: {
        afterPay: function (response) {
          me.generateResponse(response);
        }
      }
    });
    form.render('#cart-container');

    // var buttonPayment = document.querySelector("div[synap-identifier='payment-button']");
    var buttonPayment = await this.waitForElm("div[synap-identifier='payment-button']") as HTMLInputElement;

    const amount = (Math.round(this.transaction.order.amount * 100) / 100).toFixed(2);

    buttonPayment.innerHTML = `Pagar ${this.transaction.order.currency.symbol} ${this.numberWithCommas(amount)}`;

    this.cdr.detectChanges();
  }

  waitForElm(selector) {
    return new Promise(resolve => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(mutations => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    });
  }

  numberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  async startPaymentBank(transaction: Transaction, processor: Processor, gatewayUrl: string, webReference: string) {
    this.reset();
    this.paymentMethod = 'BANKS';
    this.isLoading = true;

    this.processor = processor;
    if (transaction) {
      this.transaction = transaction;

      const payment = {
        paymentCode: {
          processorCode: processor.code
        }
      };
      const settings = {
        language: "es_PE",
        businessService: "LNK",
      };
      this.transaction.payment = payment;
      this.transaction.settings = settings;

      const response = await this.paymentService.payOrder(this.transaction).toPromise();
      if (response && response.body.success) {
        this.setStyles(transaction.order.entity);
        webReference = response.body.result.processorResult.paymentCode.webReference;
      }
      else {
        this.isLoading = false;
        this.messageService.sendMessage('SET_ERROR', null);
      }
    }
    gatewayUrl = gatewayUrl + webReference;
    const gatewayIframe = "<iframe class=\"gateway-iframe-" + this.processor.code + "\" src=\"" + gatewayUrl + "\"></iframe>"
    this.urlSafe = this.sanitizer.bypassSecurityTrustHtml(gatewayIframe);
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  generateResponse(response: any) {
    const data = {
      response: response,
      transaction: this.transaction,
      processor: this.processor,
    }
    this.messageService.sendMessage('SET_RESPONSE', data);
  }

  setStyles(entity) {
    let visualLink = this.visualLinkService.setVisualLink(entity);
    this.injectStyle(visualLink);
  }

  private injectStyle(visualLink) {
    const styles = this.document.createElement('STYLE') as HTMLStyleElement;
    styles.id = 'dynamic-css';
    styles.innerHTML = this.visualLinkService.getVisualLinkStyle(visualLink);
    this.renderer.appendChild(this.document.head, styles);
  }

  reset() {
    this.transaction = null;
    this.processor = null;
    this.paymentMethod = 'CARDS';
    this.urlSafe = null;
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    const styles = this.document.getElementById('dynamic-css') as HTMLElement;
    if (styles && styles.parentElement) {
      (styles.parentElement as any).removeChild(styles);
    }
    this.cdr.detach();
  }
}
