<div class="row center-xs">
  <div class="col-xs-12">
    <div class="box">
      <div class="spinner-container">
      <div class="spinner-wrapper">
        <mat-spinner [diameter]="100"></mat-spinner>
      </div>
    </div>
    </div>
  </div>
</div>