<div *ngIf="isLoading">
    <app-landing-form #childLanding></app-landing-form>
</div>

<div *ngIf="!isLoading">
    <div *ngIf="paymentMethod == 'CARDS' && transaction">
        <div class="row center-xs">
            <div class="col-xs-12 col-sm-7">
                <div class="box" id="payment-container">
                    <mat-card>
                        <mat-card-content>
                            <div class="row start-xs">
                                <div class="col-xs-12">
                                    <div class="box">
                                        <app-header-form #childHeader></app-header-form>
                                    </div>
                                </div>
                            </div>
                            <div class="row start-xs">
                                <div class="col-xs-12">
                                    <div class="box">
                                        <div class="sub-title">
                                            <div class="sub-title-left">DATOS DE PAGO</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12">
                                    <div class="box">
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                            </div>
                            <div id="checkout-pay" class="synap-container">
                                <div id="checkout-container" class="row synap-container">
                                    <div class="blank-slate">
                                        <div id="cart-container" class="synap-container" name="cart-container">
                                            <!-- Aquí se visualizará el widget de pago -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="paymentMethod == 'BANKS'">
        <div class="row center-xs">
            <div class="col-xs-12 col-sm-7">
                <div class="box iframe-container" id="payment-container">
                    <mat-card [ngClass]="'mat-card-'+ processor.code">
                        <mat-card-content>
                            <div *ngIf="transaction" class="row start-xs">
                                <div class="col-xs-12">
                                    <div class="box">
                                        <app-header-form #childHeader></app-header-form>
                                    </div>
                                </div>
                            </div>
                            <div class="row start-xs">
                                <div class="col-xs-12">
                                    <div class="box">
                                        <div [innerHTML]="urlSafe"></div>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>